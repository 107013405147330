<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="600"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改公物仓信息' : '新建公物仓信息'"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      :class="disabled ? 'form' : ''"
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 4 }, sm: { span: 24 } }"
      labelAlign="left"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="管理单位" name="unit">
            <a-tree-select
              allow-clear
              tree-default-expand-all
              placeholder="请选择所属单位"
              v-model:value="form.unit"
              :tree-data="organizationList"
              :replaceFields="{
                children: 'children',
                title: 'organizationName',
                key: 'organizationId',
                value: 'organizationId'
              }"
              :dropdown-style="{ maxHeight: '360px', overflow: 'auto' }"
              @change="getUser"
              :disabled="disabled"
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="公物仓编号" name="warehouseCode">
            <a-input
              v-model:value="form.warehouseCode"
              placeholder="请输入公物仓编号"
              allow-clear
              :disabled="disabled"
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="管理员" name="manager">
            <a-tree-select
              allow-clear
              tree-default-expand-all
              placeholder="请选择管理员"
              v-model:value="form.manager"
              :tree-data="userList"
              :replaceFields="{
                children: 'children',
                title: 'nickname',
                key: 'userId',
                value: 'userId'
              }"
              :dropdown-style="{ maxHeight: '360px', overflow: 'auto' }"
              :disabled="disabled"
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="财务人员" name="financer">
            <a-tree-select
              allow-clear
              tree-default-expand-all
              placeholder="请选财务人员"
              v-model:value="form.financer"
              :tree-data="userList"
              :replaceFields="{
                children: 'children',
                title: 'nickname',
                key: 'userId',
                value: 'userId'
              }"
              :dropdown-style="{ maxHeight: '360px', overflow: 'auto' }"
              :disabled="disabled"
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="备注" name="remark">
            <a-input
              v-model:value="form.remark"
              placeholder="请输入备注"
              allow-clear
              :disabled="disabled"
              class="disabledColor"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import * as warehouseApi from '@/api/ams/warehouse'
import * as userApi from '@/api/sys/user'

export default {
  name: 'warehouseEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 全部机构
    organizationList: Array
  },
  components: {},
  data() {
    return {
      disabled: false,
      // 表单数据
      form: Object.assign({}, this.data),
      // 编辑弹窗表单验证规则
      rules: {
        batteryCode: [
          {
            required: true,
            message: '请输入电池编号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        brandName: [
          {
            required: true,
            message: '请输入品牌',
            type: 'string',
            trigger: 'blur'
          }
        ],
        deviceCode: [
          {
            required: true,
            message: '请输入设备编号',
            type: 'string',
            trigger: 'blur'
          }
        ],
        modle: [
          {
            required: true,
            message: '请输入型号',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      departmentList: [],
      userList: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = this.data
        this.getUser()
        this.isUpdate = true
      } else {
        this.form = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          warehouseApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    /* 获取用户 */
    getUser() {
      userApi
        .all({
          unitId: this.form.unit || -1,
          limit: 1000
        })
        .then((res) => {
          if (res.code === 0) {
            this.userList = res.data
          } else {
            this.$message.error(res.data.msg)
          }
        })
    }
  }
}
</script>

<style scoped lang='less'>
.form {
  :deep(.disabledColor) {
    background: #fff;
    opacity: 1;
    input[disabled] {
      color: black;
    }
    select[disabled] {
      color: black;
    }
    span {
      color: black;
    }
  }
}
</style>
